import React from "react";

const HomeTitle = () => {
  return (
    <div className="flex-grow flex flex-col sm:container mx-auto relative px-4 sm:px-0">
      <div className="relative px-4 sm:px-0 my-40">
        <div className="flex flex-col">
          <h1 className="text-right text-white font-poppins sm:text-8xl text-8xl mb-4">
            IvyNet
          </h1>
          <h1 className="text-right text-white font-poppins text-2xl sm:text-4xl mb-4">
            <p><span className="font-thin">A </span> coordination middleware </p>
            <p> <span className="font-thin"> for hardware operators</span></p>
          </h1>
          <h3 className="font-thin text-right text-ivy-grey-100  sm:text-xl">
            Maximize <a href="google.com" target="_blank" /* className="underline" */>the value of your compute resources</a>
          </h3>
        </div>
        <img className="fixed top-[200px] left-[150px] opacity-20 transform scale-20 pointer-events-none" src="img/radial.png" alt="radial" />
      </div>
      <hr class="h-px mb-8 bg-gray-200 border-0 dark:bg-gray-700 opacity-50" />
      <div className="sm:container mx-auto relative px-4 sm:px-0">
        <div className="flex flex-col w-72 items-end mt-12">
          <h2 className="text-right text-white font-poppins sm:text-4xl text-5xl mb-4 align-right">
            Meet iv1
          </h2>
          <h1 className="text-right text-white font-poppins text-lg sm:text-xl mb-4">
            <p><span className="font-thin">A </span> parallel, local reality</p>
            <p> <span className="font-thin"> of your dePin network</span></p>
          </h1>
          <h3 className="font-thin text-right text-ivy-grey-100 text-sm sm:text-sm opacity-50 mb-8">
            <p>iv1 is a local blockchain environment specifically designed for dePIN ecosystems.</p> By simulating resource-intensive networks, Iv1 allows developers, operators and users to understand and navigate the complexities of dePIN environment without the risks associated with live operations.
          </h3>
          <button>
            <a href="https://github.com/ivy-net/iv1" target="_blank" rel="noreferrer" class="text-white hover:text-ivy-grey-100 font-ptmono py-2 px-4 border border-white">Run iv1</a>
          </button>
        </div>
        <img className="fixed top-[700px] left-[150px] opacity-20 transform scale-20 pointer-events-none" src="img/radial.png" alt="radial" />
      </div>
    </div>

  )
}

export default HomeTitle;
